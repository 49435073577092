<template>
  <div>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="Object.assign(defaultStyle, styles)"
        :width="width"
        :height="height"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Chart",
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Bar
  },
  data() {
    return {
      chartData: {
        labels: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
        datasets: [
          {
            type: 'line',
            label: 'Profits',
            data: [5, 8, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderColor : "#405189",
            backgroundColor: '#405189',
            fill: true,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +'$'+ context.parsed.y + 'k';
                }
              }
            },
            order: 1
          },
          {
            type: 'bar',
            label: 'Users',
            data: [120, 200, 170, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#0AB39C',
            barThickness: 10,
            order: 2
          },
          {
            type: 'line',
            label: 'Wallet Connected',
            data: [10, 5, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderColor : "#F06548",
            backgroundColor: '#F06548',
            barThickness: 10,
            borderDash: [10,5],
            tension: 0.1,
            order: 1
          }
        ]
      },
      defaultStyle: {
        background: 'transparent',
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
        scales: {
          yAxes: {
            type: 'logarithmic'
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>